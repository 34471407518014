import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import "./RequestAccess.scss"
import axios from "axios"
import { ToastContainer, cssTransition, toast } from "react-toastify"
import LeftContainer from "../LeftContainer/LeftContainer"
import "react-toastify/dist/ReactToastify.css"
import { check } from "yargs"
interface forgetProps {
    setDefault: () => void
  }
const RequestAccess = (props:forgetProps) => {
    props.setDefault()
    // const [username, setUsername] = useState<string>("")
    // const [submitError, setSubmitError] = useState<number>(0)
    // const [submitSuccess, setSubmitSuccess] = useState<number>(0)
    const apiBasePath = process.env.REACT_APP_API_URL
    const basePath = process.env.REACT_APP_BASE_PATH
    const customIdRequest = 'toast-idRequest'
    const navigate = useNavigate()
    const [requestFormData, setRequestFormData] = useState<any>({
        first_name: '',
        email: '',
        phone_number: '',
        message: ''
    })
    const [formErrors, setFormErrors] = useState<any>({
        emailError: false
    })
    const EnterAnim = cssTransition({
        enter: "fade-in",
        exit: "fade-out",
        appendPosition: false,
        collapse: true,
        collapseDuration: 300
      })
    // const customId = "toast-id-email"
    // const Enter = cssTransition({
    //   enter: "fade-in",
    //   exit: "fade-out",
    //   appendPosition: false,
    //   collapse: true,
    //   collapseDuration: 300
    // })
    // const errorNotificaion = (errorMessage: string) => {
    //   toast.error(errorMessage, {
    //     position: toast.POSITION.TOP_CENTER,
    //     // eslint-disable-next-line
    //     icon: false,
    //     toastId: customId,
    //     transition: Enter,
    //     containerId: "send_email"
    //   })
    // }
    // const CloseButton = ({ closeToast }: any) => (
    //   <div
    //     className="send_email-close-btn Login-error-close-btn"
    //     onClick={closeToast}
    //   ></div>
    // )
    // const CloseButtonSuccess = ({ closeToast }: any) => (
    //   <div className="Logout-success-close-btn" onClick={closeToast}></div>
    // )
    // const handleFormSubmit = (event: React.FormEvent) => {
    //   event.preventDefault()
  
    //   const errorMsg = "The email field is required."
    //   if (username.trim() === "") {
    //     errorNotificaion(errorMsg)
    //     setSubmitError(1)
    //     setSubmitSuccess(0)
        
    //   }
    //   if (username !== "") {
    //     axios
    //       .post(`${apiBasePath}api/forgetPassword`, {
    //         email: username
    //       })
    //       .then((response) => {
    //         if (response.status === 200) {
    //           setSubmitSuccess(1)
    //           const successId = 'toast-sucess-msg'
    //           toast.success('We have sent a password recovery instructions mail.', {
    //             position: toast.POSITION.TOP_CENTER,
    //             // eslint-disable-next-line
    //             icon: false,
    //             toastId: successId,
    //             containerId: "send_email_success"
    //           })
    //           setSubmitError(0)
    //         }
    //       })
    //       .catch((error) => {
    //         const responseStatus = error.response.status
    //         const wrongMail = "Wrong Email address."
    //         const inValidMail = "The email field must be a valid email address."
    //         if (responseStatus === 401) {
    //           errorNotificaion(inValidMail)
    //         }
    //         if (responseStatus === 400) {
    //           errorNotificaion(wrongMail)
    //         }
    //         setSubmitError(1)
    //     setSubmitSuccess(0)
  
    //       })
    //   }
    // }
  
    // const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setUsername(event.target.value)
    //   setSubmitError(0)
    //   setSubmitSuccess(0)
    //   toast.dismiss(customId)
    //   toast.dismiss('toast-sucess-msg')    
    // }
  
    const backToLogin = () => {
      if (`${basePath}` !== "") {
        navigate(`${basePath}/login`)
      } else {
        navigate(`/login`)
      }
    }
    const isEmailValid = (email: string): boolean => {
        // Use a regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleEmailBlur = () => {
        setFormErrors({
          ...formErrors,
          emailError: !isEmailValid(requestFormData.email),
        });
      }
    const handleRequestAccess = (event: React.FormEvent) => {
        event.preventDefault();
    
        // Validation
        const isValid =
          requestFormData.first_name.length >= 3 &&
          isEmailValid(requestFormData.email) &&
          requestFormData.phone_number.length > 0 &&
          requestFormData.message.length > 0;
    
        if (isValid) {
          const formData = new FormData();
          formData.append("first_name", requestFormData.first_name);
          formData.append("email", requestFormData.email);
          formData.append("phone_number", requestFormData.phone_number);
          formData.append("message", requestFormData.message);
    
          axios
            .post(`${apiBasePath}api/requestAccess`, formData, {})
            .then((response) => {
              const data = response.data;
              const token = data.token;
              const refreshToken = data.refresh_token;
    
              if (data.status === 200) {
                setRequestFormData({
                  first_name: "",
                  email: "",
                  phone_number: "",
                  message: "",
                });
                successNotification(response.data.message);
              }
            })
              .catch((error) => {
                // errorNotificaion("Invalid Email ID or Password")
              })
          }
          }

      console.log(requestFormData, 'requestFormData');
      const successNotification = (successMessage: string) => {
        toast.error(successMessage, {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            toastId: customIdRequest,
            transition: EnterAnim,
            containerId: "request-success"
          })
      }
      const CloseButtonSuccess = ({ closeToast }: any) => {
        const handleEnterKey = (event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            closeToast();
          }
        };
      
        return (
          <div
            tabIndex={0}
            className="Logout-success-close-btn"
            onClick={closeToast}
            onKeyDown={handleEnterKey}
          >
          </div>
        );
      };
    return (
      <div className="container-fluid">
        <div className="row">
          <LeftContainer />
          <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7 right-container">
            <div className="col-sm-12 col-xs-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 right-container__loginParent request-section">
                <ToastContainer
                        containerId={"request-success"}
                        className="notification-container-success"
                        toastClassName="notification-wrapper-success request-success"
                        bodyClassName="notification-body-success"
                        closeButton={CloseButtonSuccess}
                        hideProgressBar={true}
                        autoClose={false}
                    />
                <div className="right-container__loginForm">
                    <div className="right-container__txtContainer mx-auto mb-1 mt-2 forgot-passowrd">
                        <span className="right-container__signInTxt mb-2">
                            Request Access
                        </span>
                        <span className="request-subText">
                            Embrace the Power of Words with our Alt Text
                        </span>
                    </div>
                    <form id="accessForm" onSubmit={handleRequestAccess}>
                        <div className="mt-3 mb-3 access-name">
                            <input name="first-name" type="text" className="form-control" id="firstName" placeholder="First Name" 
                            onChange={(e)=>
                                setRequestFormData({...requestFormData, first_name: e.target.value})
                            }
                            value={requestFormData.first_name} />
                        </div>
                        <div className="mt-3 mb-3 access-email">
                            <input name="email" type="email"
                            className={`form-control ${
                                formErrors.emailError ? "is-invalid" : ""
                              }`}
                            id="inputEmail" placeholder="Email Address" 
                            onChange={(e)=>
                                setRequestFormData({...requestFormData, email: e.target.value})
                            } 
                            onBlur={handleEmailBlur}
                            value={requestFormData.email} />
                            {formErrors.emailError && (
                            <div className="invalid-feedback">Invalid email address</div>
                            )}
                        </div>
                        <div className="mt-3 mb-3 access-contact">
                            <input name="phone-number" type="number" className="form-control" id="inputNumber" placeholder="Phone Number" 
                            onChange={(e)=>
                                setRequestFormData({...requestFormData, phone_number: e.target.value})
                            } 
                            value={requestFormData.phone_number}/>
                        </div>
                        <div className="access-message mt-3 mb-3">
                            <textarea name="message" className="form-control" id="inputMessage" placeholder="Message" rows={3} value={requestFormData.message}
                            onChange={(e)=>
                                setRequestFormData({...requestFormData, message: e.target.value})
                            } 
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="right-container__requestBtn mt-2 mb-3"
                            disabled={
                              !(
                                requestFormData.first_name &&
                                isEmailValid(requestFormData.email) &&
                                requestFormData.phone_number &&
                                requestFormData.message
                              )
                            }
                          >
                            Submit
                        </button>

                    </form>
                    <div className="backToLogin">
                        Already have an account. <span className="login-text" onClick={backToLogin}>Login</span> here
                    </div>
                </div>
                <div className="loginFooter mt-5">
                    <div className="footer-logo mb-2">
                        <span className="logoImg" role="img" aria-label="equalsense logo on login page at copyright side"></span> | 
                        <img
                            src={require("../../assets/images/hurixlogo.png")}
                            alt="hurix logo icon for footer login"
                            className="footer-hurix-logo"
                            />
                    </div>
                    <div className="copyrightText">Copyright&copy;2024 equalsense.ai. All rights reserved.</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default RequestAccess