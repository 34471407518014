import React, { useEffect, useState } from "react"
import "./Login.scss"
import { cssTransition, toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import ForgetPassword from "../ForgetPassword/ForgetPassword"
import RequestAccess from "../RequestAccess/RequestAccess"
import axios from "axios"
import LeftContainer from "../LeftContainer/LeftContainer"
// import learnquillLogo from "./../../assets/images/Dictera-Logo-Black.png"
interface LoginProps {
  onLogin: () => void
  isloggedOut: boolean
  sessionExpired: boolean
}
const Login = (props: LoginProps) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>("")
  const [loginError, setLoginError] = useState<boolean>(false)
  const [logoutError, setLogoutError] = useState<boolean>(false)
  const [passwordType, setPasswordType] = useState<string>("password")
  const [passwordInput, setPasswordInput] = useState<string>("")
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPasswordInput(e.currentTarget.value)
  }
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
    setLoginError(false)
  }
  const showForgetPassword = () => {
    navigate(`${basePath}/ForgetPassword`)
  }
  const showRequestAccess = () => {
    navigate(`${basePath}/RequestAccess`)
  }
  const togglePassword = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      const form = document.getElementById("loginForm") as HTMLFormElement
      handleFormSubmit.call(form, event)
      // setLogoutError(false)

      // form.submit()
    }
  }
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // setLogoutError(false)
  }

  useEffect(() => {
    if (props.isloggedOut && !props.sessionExpired) {
      setLogoutError(true)
      setTimeout(() => {
        logOutNotification("You have logged out successfully")
      }, 100)
    }
  }, [props.isloggedOut])
  useEffect(() => {
    // console.log(props.isloggedOut, "props.isloggedOut")

    if (props.sessionExpired) {
      // alert()
      setLogoutError(true)
      setTimeout(() => {
        logOutNotification("Your session has expired.")
      }, 100)
    }
  }, [props.sessionExpired])
  const padZero = (num: any) => {
    return num.toString().padStart(2, "0")
  }
  const setCurrentTime = () => {
    const currentTime = new Date()
    const updatedTime = new Date(currentTime)
    updatedTime.setHours(updatedTime.getHours() + 1)
    const year = updatedTime.getFullYear()
    const month = padZero(updatedTime.getMonth() + 1)
    const date = padZero(updatedTime.getDate())

    const hours = updatedTime.getHours()
    const minutes = updatedTime.getMinutes()
    const seconds = updatedTime.getSeconds()
    const formattedUpdatedTime = `${year}-${month}-${date} ${padZero(
      hours
    )}:${padZero(minutes)}:${padZero(seconds)}`
    return formattedUpdatedTime
  }
  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setLogoutError(false)
    axios
      .post(`${apiBasePath}api/login`, {
        email: username,
        password: passwordInput
      })
      .then((response) => {
        const data = response.data
        const token = data.token
        const refreshToken = data.refresh_token
        // const expiryTime = data.expires_at
        // console.log(response)

        if (token && data.status === 200) {
          const expire_time = setCurrentTime()
          // console.log(expire_time, 'expire_time');

          setLoginError(false)
          localStorage.clear()
          localStorage.setItem("user-token", token)
          localStorage.setItem("refresh_token", refreshToken)
          localStorage.setItem("expires_at", expire_time)
          props.onLogin()
        } else {
          setLoginError(true)
          errorNotificaion(data.message)
        }
      })
      .catch((error) => {
        if(error.response.data.error.email || error.response.data.error.password ) {
            setLoginError(true)
            errorNotificaion("Email id and password required")
          }
          else {
            setLoginError(true)
            errorNotificaion("Invalid Email ID or Password")

          }
      })
  }

  const customId = "toast-id-login"
  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      transition: Enter,
      containerId: "loginError"
    })
  }
  const CloseButton = ({ closeToast }: any) => (
    <div className="Login-error-close-btn" onClick={closeToast}></div>
  )

  const customIdLogout = "toast-id-logOut"
  const EnterAnim = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })
  const logOutNotification = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customIdLogout,
      transition: EnterAnim,
      containerId: "logOut-success"
    })
  }
  const CloseButtonSuccess = ({ closeToast }: any) => {
    const handleEnterKey = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        closeToast();
      }
    };
  
    return (
      <div
        tabIndex={0}
        className="Logout-success-close-btn"
        onClick={closeToast}
        onKeyDown={handleEnterKey}
      >
      </div>
    );
  };
  useEffect(() => {
    const handleEscPress = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        // Close the toast when the Esc key (key code 27) is pressed
        toast.dismiss(customIdLogout) // Dismiss all toasts in the container
        toast.dismiss(customId) // Dismiss all toasts in the container
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleEscPress)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleEscPress)
    }
  }, [])
  return (
    <div className="container-fluid">
      <div className="row">
        <LeftContainer />
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7 right-container">
          <div className="col-sm-12 col-xs-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 right-container__loginParent">
            <div className="right-container__loginForm">
              {loginError && (
                <ToastContainer
                  containerId={"loginError"}
                  className="notification-container"
                  toastClassName="notification-wrapper"
                  bodyClassName="notification-body"
                  closeButton={CloseButton}
                  hideProgressBar={true}
                  autoClose={false}
                />
              )}
              {props.isloggedOut && logoutError && (
                <ToastContainer
                  containerId={"logOut-success"}
                  className="notification-container-success"
                  toastClassName="notification-wrapper-success"
                  bodyClassName="notification-body-success"
                  closeButton={CloseButtonSuccess}
                  hideProgressBar={true}
                  autoClose={false}
                />
              )}
              <div className="right-container__txtContainer mx-auto mb-3 mt-4">
                <span className="right-container__signInTxt mb-1">
                  Sign in to your account
                </span>
                <span className="right-container__subLine">
                  Embrace the Power of Words with our Alt Text
                </span>
              </div>
              <form id="loginForm" onSubmit={handleFormSubmit}>
                <div className="mt-3 mb-3 mx-auto right-container__emailField">
                  <input
                    className="form-control"
                    id="inputEmail"
                    placeholder="Email Address"
                    value={username}
                    onChange={handleUsernameChange}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                </div>
                <div className="mt-3 mb-3 mx-auto right-container__passwordField">
                  <input
                    type={passwordType}
                    onChange={handlePasswordChange}
                    value={passwordInput}
                    className="form-control"
                    id="inputPassword"
                    placeholder="Password"
                    onKeyDown={handleKeyDown}
                    maxLength={32}
                  />
                  <div className="togglePassword">
                    <button
                      type="submit"
                      role="button"
                      aria-label={`eye icon to ${passwordType === "password" ? "show" : "hide"} password`}
                      className={`eye-btn ${
                        passwordType === "password" ? "hidden" : "visible"
                      }`}
                      onClick={togglePassword}
                    ></button>
                  </div>  
                </div>
                <div className="forgotPwd">
                  <a href="#" onClick={showForgetPassword}>
                    Forgot password?
                  </a>
                </div>
                <div></div>
                <button type="submit" className="right-container__submitBtn">
                  Sign In
                </button>
              </form>
            </div>
            <div className="request-access mt-3">
              <span className="request-text">If you don`t have an account. <a href="#" className="request-link" onClick={showRequestAccess}>Request Access</a></span>
            </div>
            <div className="ai-product mt-4">
              <span className="product-text">Let&apos;s try our other AI products</span>
              <div className="product-details mt-2">
                {/* <div className="learnquill">
                  <img
                    src={require("../../assets/images/learnqill.png")}
                    alt="Logo of LearnQuill AI product"
                    className="learnquill-img"
                  />
                  <span><a href="https://www.learnquil.ai/" className="learnquill-link learnQuill-link" target="blank">Try Now</a></span>
                </div> */}
                <div className="dictera">
                  <img
                      src={require("../../assets/images/Dictera-Logo-Black.png")}
                      alt="Logo of Dictera AI product"
                      className="dictera-img"
                    />
                    <span><a href="https://www.dictera.com/" className="learnquill-link dictera-link" target="blank">Try Now</a></span>
                </div>
              </div>
            </div>
            <div className="loginFooter mt-5">
              <div className="footer-logo mb-2">
                <span className="logoImg" role="img" aria-label="equalsense logo on login page at copyright side"></span> | 
                <img
                      src={require("../../assets/images/hurixlogo.png")}
                      alt="hurix logo icon for footer login"
                      className="footer-hurix-logo"
                    />
              </div>
              <div className="copyrightText">
                Copyright&copy;2024 equalsense.ai. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const padZero = (num: any) => {
  return num.toString().padStart(2, "0")
}

export default Login
