import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "./ResetPassword.scss"
import { cssTransition, toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LeftContainer from "../LeftContainer/LeftContainer"
import axios from "axios"
interface resetPwdProps {
  setDefault: () => void
}

const ResetPassword = (props: resetPwdProps) => {
  props.setDefault()
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const { email } = useParams()
  const [resetButtonState, setResetButtonState] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [newPasswordType, setNewPasswordType] = useState<string>("password")
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<string>("password")
  const navigate = useNavigate()

  const customId = "toast-id-email"
  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      transition: Enter,
      containerId: "send_email"
    })
  }
  const CloseButton = ({ closeToast }: any) => (
    <div
      className="send_email-close-btn Login-error-close-btn"
      onClick={closeToast}
    ></div>
  )
  // console.log(email, "id")

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    let isValidPassword = 0
    const hasSpaces = (str: string) => /\s/.test(str)
    if (hasSpaces(newPassword) || hasSpaces(confirmPassword)) {
      errorNotificaion("Spaces are not allowed.")
    }
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      errorNotificaion("All fields are required.")
      isValidPassword = 1
    }
    if (newPassword.length < 8 || confirmPassword.length < 8) {
      errorNotificaion("Password must have minimum 8 characters.")
      isValidPassword = 1
    } else if (!/\d/.test(newPassword) || !/\d/.test(confirmPassword)) {
      errorNotificaion("Password must have at least 1 numerical value.")
      isValidPassword = 1
    } else if (!/[A-Z]/.test(newPassword) || !/[A-Z]/.test(confirmPassword)) {
      errorNotificaion("Password must have at least 1 uppercase alphabet.")
      isValidPassword = 1
    } else if (!/[a-z]/.test(newPassword) || !/[a-z]/.test(confirmPassword)) {
      errorNotificaion("Password must have at least 1 lowercase alphabet.")
      isValidPassword = 1
    } else if (
      !/[^a-zA-Z0-9]/.test(newPassword) ||
      !/[^a-zA-Z0-9]/.test(confirmPassword)
    ) {
      errorNotificaion("Password must have at least 1 special character.")
      isValidPassword = 1
    }
    if (newPassword !== confirmPassword) {
      errorNotificaion("New and Confirm passwords must be same.")
      setResetButtonState("")
      isValidPassword = 1
    }

    if (isValidPassword === 0 && newPassword === confirmPassword) {
      setResetButtonState("disabled")
      axios
        .post(`${apiBasePath}api/resetPassword/${email}`, {
          password: newPassword,
          password_confirmation: confirmPassword
        })
        .then((response) => {
          if (response.status === 200) {
            if (`${basePath}` !== "") {
              navigate(`${basePath}`)
            } else {
              navigate(`/`)
            }
          }
        })
        .catch((error) => {
          const responseStatus = error.response.status
          const wrongMail = "Wrong Email address."
          const inValidMail = "The email field must be a valid email address."
          // console.log(error.response.data.error, "===", error)
          if (responseStatus === 401) {
            errorNotificaion(inValidMail)
          }
          if (responseStatus === 400) {
            errorNotificaion(wrongMail)
          }
          setResetButtonState("")
        })
    } else {
      setResetButtonState("")
    }
  }
  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value)
    toast.dismiss(customId)
  }
  const handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value)
    toast.dismiss(customId)
  }

  const backToLogin = () => {
    if (`${basePath}` !== "") {
      navigate(`${basePath}/login`)
    } else {
      navigate(`/login`)
    }
  }
  const togglePassword = (field: string) => {
    if (field === "new") {
      setNewPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      )
    } else if (field === "confirm") {
      setConfirmPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      )
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <LeftContainer />
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7 right-container">
          <div className="col-sm-12 col-xs-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 right-container__loginParent">
            <div className="right-container__loginForm mx-auto">
              <ToastContainer
                containerId={"send_email"}
                className="notification-container"
                toastClassName="notification-wrapper"
                bodyClassName="notification-body"
                closeButton={CloseButton}
                hideProgressBar={true}
                autoClose={false}
              />
              <div className="right-container__txtContainer mx-auto mb-3 mt-4 forgot-passowrd">
                <span className="right-container__signInTxt mb-1">
                  Reset Passoword
                </span>
                <span className="right-container__subLine">
                  Your new password must be different from previously used
                  password
                </span>
              </div>
              <form id="emailForm" onSubmit={handleFormSubmit}>
                <div className="mt-5 mb-5 mx-auto right-container__emailField">
                  <input
                    type={newPasswordType}
                    className="form-control"
                    id="inputEmail_1"
                    placeholder="Enter new Passowrd"
                    value={newPassword}
                    onChange={handleNewPassword}
                    maxLength={32}
                  />
                  <div className="togglePassword">
                    <button
                      type="button"
                      className={`eye-btn ${
                        newPasswordType === "password" ? "hidden" : "visible"
                      }`}
                      onClick={() => togglePassword("new")}
                    ></button>
                  </div>
                </div>
                <div className="mt-5 mb-5 mx-auto right-container__passwordField">
                  <input
                    type={confirmPasswordType}
                    className="form-control"
                    id="inputEmail"
                    placeholder="Confirm new Passowrd"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    maxLength={32}
                  />
                  <div className="togglePassword">
                    <button
                      type="button"
                      className={`eye-btn ${
                        confirmPasswordType === "password"
                          ? "hidden"
                          : "visible"
                      }`}
                      onClick={() => togglePassword("confirm")}
                    ></button>
                  </div>
                </div>
                <button
                  type="submit"
                  className={`right-container__submitBtn btn btn-primary ${resetButtonState}`}
                >
                  Reset Password
                </button>
              </form>
              <button
                type="button"
                className="right-container__backToLogin-btn m-5"
                onClick={backToLogin}
              >
                Back to Login
              </button>
            </div>
            <div className="loginFooter">
              <div className="logoImg"></div>
              <div className="copyrightText">
                Copyright&copy;2024 equalsense.ai. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
