import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./Header.scss"
import axios from "axios"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


interface HeaderProps {
  onLogout: () => void
  generateRandomKey: () => void
}
const Header = (props: HeaderProps) => {
  const navigate = useNavigate()
  const [userName, setUsername] = useState<string>("")
  const [emailId, setEmailId] = useState<string>("")
  const [isAdminLogin, setIsAdminLogin] = useState(false) // check Admin Login
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH

  const logout = () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null) {
      axios
        .get(`${apiBasePath}api/logout`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            localStorage.clear()
            if (`${basePath}` !== "") {
              navigate(`${basePath}/`)
            } else {
              navigate(`/`)
            }
            props.onLogout()
          }
        })
        .catch((error) => {
          // console.log(error, "error")
        })
    } else {
      props.onLogout()
      localStorage.clear()
      if (`${basePath}` !== "") {
        navigate(`${basePath}`)
      } else {
        navigate(`/`)
      }
    }
  }
  const dashboard = () => {
    props.generateRandomKey()
    localStorage.setItem(`${basePath}/Dashboard`, "1")
    navigate(`../${basePath}/Dashboard`)
  }

  const upload = () => {
    toast.dismiss()
    props.generateRandomKey()
    navigate(`../${basePath}/Upload`)
  }
  const changePassword = () => {
    navigate(`../${basePath}/ChangePassword`)
  }
  useEffect(() => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/getUser`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          const data = response.data.data
          // console.log(data)
          if (response.status === 200) {
            setUsername(data.name)
            setEmailId(data.email)
            localStorage.setItem("user_id", data.user_id)
            localStorage.setItem("client_id", data.client_id)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // console.log(error, "ERROR")
            setUsername("Unauthorized Access")
            setEmailId("Unauthorized Access")
          }
        })
  }, [])
  const customId = "toast-id-logOut"
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "Logout-success"
    })
  }

  useEffect(() => {
    isAdmin()
  }, [])

  const isAdmin = async () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/admin/checkUsersRoleData`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          // console.log(response.data)

          const data = response.data.data
          if (response.status === 200) {
            if (data.usertype === "Admin" || data.usertype === "Super admin") {
              setIsAdminLogin(true)
            }
          }
        })
        .catch((error) => {
          // console.log(error)
        })
  }

  return (
    <header>
      <a href="#mainContent" className="skip-main"  onMouseDown={(event) => { event.preventDefault(); }}>Skip to Main Content</a>
      <nav className="navbar container navbar-expand-lg header-navbar mx-auto p-3">
        <a href="#" className="navbar-brand me-5" onClick={dashboard} aria-label="Equalsense logo">
        <img
                    src={require("../../assets/images/logo-equalsense-beta.png")}
                    alt="homepage logo"
                  />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a href="#" className="nav-link custom-focus mx-1" onClick={dashboard}>
                Dashboard
              </a>
            </li>
            {isAdminLogin && (
              <li className="nav-item">
                <a href="#" className="nav-link custom-focus mx-1" onClick={upload}>
                  Upload
                </a>
              </li>
            )}
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle custom-focus"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="profile">
                  <span className="profile-name user-creds">{userName}</span>
                  <span className="email-id user-creds">{emailId}</span>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <a
                  tabIndex={0}
                  className="dropdown-item change-password"
                  onClick={changePassword}
                  onKeyDown={(event:any) => {
                    if (event.key === 'Enter') {
                      changePassword();
                      const dropdownMenu = document.querySelector('.dropdown-menu');
                      if (dropdownMenu) {
                        dropdownMenu.classList.remove('show');
                      }                    
                    }
                  }}
                >
                  <img
                    src={require("../../assets/images/change_password.png")}
                    alt="change password"
                    aria-hidden="true"
                  />
                  <span className="change-password__text">Change Password</span>
                </a>
                <a tabIndex={0} className="dropdown-item logout" onClick={logout}
                onKeyDown={(event:any) => {
                  if (event.key === 'Enter') {
                    logout();                   
                  }
                }}
                >
                  <img
                    src={require("../../assets/images/logout-button.png")}
                    alt="Logout"
                    aria-hidden="true"
                  />
                  <span className="logout__text">Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
export default Header
