import React, { useEffect, useState } from "react"
import { cssTransition, toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


// const Home = () => {
//   const [htmlContent, setHtmlContent] = useState<string | null>(null);
//   useEffect(() => {
//     const fetchHtml = async () => {
//         const response = await fetch('myHome.html'); // Replace with the correct file path
//         const text = await response.text();
//         setHtmlContent(text);
//     };

//     fetchHtml();
// }, []);

// return (
//     <div>
//         {htmlContent ? (
//             <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
//         ) : (
//             <p>Loading...</p>
//         )}
//     </div>
// );
// };

// export default Home;


const Home = () => {
  return (
    <iframe
      src="myHome.html"
      width="100%"
      height="100%"
      title="Embedded HTML"
    />
  );
};

export default Home;
